<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col xl="2" xs="0">
            <b-card>
              <!-- Categories -->
              <b-row>
                <b-col>
                  <v-select
                    v-model="selectedFilter"
                    :options="filterOptions"
                    :placeholder="this.$t('Sıralama Seçeneği')"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">{{
                        $t(label)
                      }}</span>
                    </template>
                    <template #option="{ label }">
                      <span>{{ $t(label) }}</span>
                    </template>
                  </v-select>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>
                  <h4 class="filter-title">{{ $t("Kategoriler") }}</h4>
                  <b-row
                    v-for="kategori in kategoriler"
                    :key="kategori.id"
                    style="margin-left: 5px; margin-top: 5px"
                  >
                    <b-col>
                      <span
                        class="hover-effect"
                        @click="kategoriSec(kategori)"
                        style="color: black"
                        >{{ kategori }}</span
                      >
                    </b-col>
                  </b-row>
                  <b-row style="margin-top: 20px">
                    <b-col
                      cols="12"
                      v-for="secililer in seciliKategoriler"
                      :key="secililer.id"
                      style="margin-top: 5px"
                    >
                      <b-button
                        @click="kategoriBos(secililer)"
                        variant="outline-danger"
                        size="sm"
                        v-if="seciliKategoriler.length != 0"
                      >
                        {{ secililer }}
                        <feather-icon icon="XIcon" size="14" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col xl="10" xs="12">
            <b-row>
              <b-col cols="9">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    @input="performSearch"
                    v-model="searchTerm"
                    placeholder="Search Product"
                    class="search-product"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="3">
                <v-select
                  v-model="selectedSort"
                  :options="sortOptions"
                  :placeholder="this.$t('Sıralama Seçeneği')"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">{{
                      $t(label)
                    }}</span>
                  </template>
                  <template #option="{ label }">
                    <span>{{ $t(label) }}</span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
            <b-row style="overflow-y: scroll; margin-top: 20px; height: 69vh">
              <b-col
                xl="3"
                md="4"
                sm="6"
                xs="12"
                v-for="product in displayedProducts"
                :key="product.id"
              >
                <b-card no-body>
                  <div class="item-img text-center">
                    <b-img
                      fluid
                      class="card-img-top"
                      :src="product.LINK"
                      style="height: 300px; object-fit: contain"
                    />
                  </div>

                  <!-- Product Details -->
                  <b-card-body>
                    <b-row>
                      <b-col cols="12">
                        <h6 class="item-name" style="height: 35px">
                          {{ product.AD }}
                        </h6>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="5">
                        <h5 class="item-name" style="color: black">
                          {{ product.KOD }}
                        </h5>
                      </b-col>
                      <b-col cols="7" style="text-align: right">
                        <h5
                          v-if="product.ONAYLI_FIYAT_VARMI == 1"
                          style="color: #03bdae"
                        >
                          <feather-icon
                            style="color: #03bdae !important"
                            size="16"
                            icon="CheckIcon"
                          />
                          {{ $t("Listenizde Mevcut") }}
                        </h5>
                        <h5 v-if="product.ONAYLI_FIYAT_VARMI == 0"></h5>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Product Actions -->
                  <div class="item-options text-center">
                    <b-row>
                      <b-col cols="4" style="margin: 0; padding-right: 0">
                        <b-button variant="light" block @click="detay(product)">
                          <span>{{ $t("Detay") }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="8" style="margin: 0; padding-left: 0">
                        <b-button
                          variant="primary"
                          v-if="product.BEKLEMEDE_TEKLIF_ISTEME_VARMI == 0"
                          block
                          v-b-modal.Tmodal
                          @click="
                            () => {
                              teklifiste(product);
                              tModal = true;
                            }
                          "
                        >
                          <span style="color: black">{{
                            $t("Teklif İste")
                          }}</span>
                        </b-button>
                        <b-button
                          variant="warning"
                          disabled
                          block
                          v-if="product.BEKLEMEDE_TEKLIF_ISTEME_VARMI == 1"
                        >
                          <span style="color: black">{{
                            $t("Teklif İstendi")
                          }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row style="margin-top: 1%">
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredProducts.length"
                  :per-page="productsPerPage"
                  first-number
                  align="center"
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal v-model="detailModal" centered :hide-footer="true" size="xl">
      <b-row>
        <b-col cols="5">
          <b-img fluid class="card-img-top" :src="selectedProduct.LINK" />
        </b-col>
        <b-col cols="7">
          <b-card>
            <b-row>
              <b-col>
                <span
                  >{{ selectedProduct.AD }} ({{
                    selectedProduct.INGACIKLAMA
                  }})</span
                >
              </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
              <b-col>
                <span>{{ selectedProduct.KOD }}</span>
              </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
              <b-col>
                <h5 style="color: black">
                  {{ $t("Adet") }} : {{ selectedProduct.ADET_MIKTAR }}
                </h5>
              </b-col>
            </b-row>

            <b-row style="margin-top: 5px">
              <b-col>
                <h5 style="color: black">
                  {{ $t("M3") }} : {{ selectedProduct.M3 }}
                </h5>
              </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
              <b-col>
                <h5 style="color: black">
                  {{ $t("Net") }} : {{ selectedProduct.NET }}
                </h5>
              </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
              <b-col>
                <h5 style="color: black">
                  {{ $t("Brüt") }} : {{ selectedProduct.BRUT }}
                </h5>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-model="tModal" :title="$t('Teklif İste')" hide-footer>
      <p class="text-center mt-3">
        {{ $t("Fiyat Teklifi Almak İstediğinize Emin misiniz") }}
      </p>
      <b-row>
        <b-col cols="6">
          <b-button
            class="mt-2 w-48"
            style="float: left"
            variant="danger"
            block
            @click="closePopup()"
            >{{ $t("İptal") }}</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button
            class="mt-2 w-48"
            style="float: right"
            variant="success"
            block
            @click="teklifSend()"
            >{{ $t("Evet") }}</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import vSelect from "vue-select";
import { th } from "vuejs-datepicker/src/locale";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

function formatSayi(sayi, ozellestirilmisSecenekler) {
  return new Intl.NumberFormat(undefined, ozellestirilmisSecenekler).format(
    sayi
  );
}
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    VueSlider,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },

  data() {
    return {
      ozellestirilmisSecenekler: {
        minimumFractionDigits: 2, // küsürat kısmının minimum hane sayısı
        maximumFractionDigits: 2, // küsürat kısmının maksimum hane sayısı
        minimumIntegerDigits: 2, // tam sayı kısmının minimum hane sayısı
        useGrouping: true, // binlik grup ayırıcıları kullanılsın mı?
      },
      userData: "",
      products: [],
      statusOptions: ["Artan", "Azalan"],
      statusFilter: "Artan",
      rows: 0,
      show: false,
      detailModal: false,
      tModal: false,
      selectedProduct: "",
      searchTerm: "",
      totalProducts: 0, // Toplam ürün sayısı
      currentPage: 1,
      productsPerPage: 12,
      ascendingSort: true,
      selectedSort: null,
      selectedFilter: "Tümü",
      kategoriler: [],
      seciliKategoriler: [],
    };
  },
  computed: {
    filteredProducts() {
      // Arama terimine ve seçilen sıralamaya göre ürünleri filtrele
      let filtered = this.products.filter((product) =>
        product.AD.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      if (this.seciliKategoriler.length > 0) {
        filtered = filtered.filter((product) =>
          this.seciliKategoriler.includes(product.KATEGORI)
        );
      }
      if (this.selectedSort === "Artan") {
        filtered = filtered.sort((a, b) => a.PRICE - b.PRICE);
      } else if (this.selectedSort === "Azalan") {
        filtered = filtered.sort((a, b) => b.PRICE - a.PRICE);
      }
      if (this.selectedFilter === "Tümü") {
      } else if (this.selectedFilter === "Listemde Mevcut") {
        filtered = filtered.filter((s) => s.ONAYLI_FIYAT_VARMI == 1);
      } else if (this.selectedFilter === "Listemde Olmayan") {
        filtered = filtered.filter((s) => s.ONAYLI_FIYAT_VARMI != 1);
      }

      return filtered;
    },
    displayedProducts() {
      // Sayfalama için ürünleri filtrele
      const start = (this.currentPage - 1) * this.productsPerPage;
      const end = start + this.productsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    sortOptions() {
      // Sıralama seçeneklerini tanımlayın
      return ["Artan", "Azalan"];
    },
    filterOptions() {
      // Sıralama seçeneklerini tanımlayın
      return ["Listemde Mevcut", "Listemde Olmayan", "Tümü"];
    },
  },

  created() {
    var uData = JSON.parse(localStorage.getItem("B2BUserData"));
    this.userData = uData;
    this.listele();
  },
  methods: {
    kategoriBos(data) {
      var secililer = [];

      for (var i = 0; i < this.seciliKategoriler.length; i++) {
        this.seciliKategoriler[i];
        if (this.seciliKategoriler[i] != data) {
          secililer.push(this.seciliKategoriler[i]);
        }
      }
      this.seciliKategoriler = secililer;
    },
    kategoriSec(data) {
      var satirlar = this.seciliKategoriler.filter((s) => s == data);
      if (satirlar.length == 0) {
        this.seciliKategoriler.push(data);
      }
    },
    performSearch() {
      // Arama terimine göre ürünleri filtrele ve sayfa numarasını sıfırla
      this.currentPage = 1;
    },
    async listele() {
      this.show = true;
      var request = {
        queryKey: "B2B_Tum_Urun_Listele",
        args: [
          {
            key: "@KRITER1",
            value: this.userData.musteriKod,
          },
        ],
      };

      this.$http
        .post("v1/Dynamic/GetAllWithParams", request)
        .then(async (response) => {
          if (response.status == 200) {
            var say = 0;
            var satirlar = JSON.parse(response.data.data.dataJson);

            satirlar.forEach((element) => {
              element.EN = formatSayi(element.EN);
              element.BOY = formatSayi(element.BOY);
              element.YUKSEKLIK = formatSayi(element.YUKSEKLIK);
              element.M3 = element.M3.toFixed(5);

              element.PRICEDIT =
                element.PRICE.toString() + " " + element.PRICEUNIT2;
              element.LINK =
                "https://document.d7ksuper.com/Dokuman/linkal/ucantay/" +
                element.KOD.substring(0, 8) +
                ".png";

              element.GUID = say;
              say = say + 1;

              if (element.KATEGORI != "") {
                var filter = this.kategoriler.filter(
                  (s) => s == element.KATEGORI
                );
                if (filter.length == 0) {
                  this.kategoriler.push(element.KATEGORI);
                }
              }
            });
            this.products = satirlar;
            this.rows = satirlar.length;
            this.totalProducts = satirlar.length;
            this.show = false;
            //console.log(satirlar);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async detay(data) {
      this.show = true;
      this.selectedProduct = "";
      this.selectedProduct = data;
      this.detailModal = true;
      this.show = false;
    },
    async teklifiste(data) {
      this.selectedProduct = data;
    },
    async teklifSend() {
      this.tModal = false;

      var jsonmodel = {
        MUSTERIADI: this.userData.musteriAd,
        MUSTERIKODU: this.userData.musteriKod,
        AD: this.selectedProduct.AD,
        KOD: this.selectedProduct.KOD,
        PREPRICE: this.selectedProduct.PRICE,
        PRICE: 0,
        PRICEUNIT: this.selectedProduct.PRICEUNIT.trim(),
        KRI_TARIH_1: "",
        KRI_TARIH_2: "",
        TRNUM: "",
        EVRAKNO: "",
        DURUM: 4,
        SF_UNIT: this.selectedProduct.SF_UNIT.trim(),
        SEVKIYATHESABI: "",
        KANAL: this.userData.kanal,
      };

      var jsonconvert = JSON.stringify(jsonmodel);
      this.show = true;
      try {
        var request = {
          FunctionName: "d7kRun",
          MacroName: "D7KSUP007",
          JsonParams: jsonconvert,
          MacroKey: "FiyatListesiOlustur",
        };
        await this.$http
          .post("Com/RunMacroWithAuth", request)
          .then((response) => {
            if (response.data.succeeded == true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Basarili"),
                  icon: "BellIcon",
                  text: this.$t("Teklif Talebiniz İletildi."),
                  variant: "success",
                },
              });
              var satir = this.products.filter(
                (s) => s.GUID == this.selectedProduct.GUID
              );
              satir[0].DURUM = 0;
              satir[0].BEKLEMEDE_TEKLIF_ISTEME_VARMI = 1;
              this.show = false;
            } else {
              var response0 = JSON.parse(response.data.data);
              if (response0.message == "20240201165701") {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t(
                      "Ürün için fiyat Talebiniz onay aşamasında, süreç tamamlanana kadar, Tekrar talep geçilemez.!"
                    ),
                  },
                });
              } else if (response0.message == "20240201165702") {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t(
                      "Ürün için fiyat Talebiniz alınmıştır, Tekrar talep geçilemez.!"
                    ),
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t("Hatalı İşlem Lütfen Kontrol Ediniz !"),
                  },
                });
              }

              this.show = false;
            }
          });
      } catch (ex) {
        this.show = false;
        console.log(ex, "Hata");
      }
    },
    async closePopup() {
      this.tModal = false;
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style >
.hover-effect {
  transition: font-size 0.3s, text-shadow 0.3s; /* Geçiş efekti eklemek için */
}

.hover-effect:hover {
  font-size: 18px; /* Font boyutunu büyüt (örneğin) */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Metne gölge ekle (örneğin) */
}
</style>
 
 